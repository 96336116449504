if (document.body.classList.contains("PortfolioPage")) {
	const scrollTestimonials = direction => {
		let items = Array.from(testimonials.querySelectorAll(".testimonial"));
		let currentItem = testimonials.querySelector(".testimonial.current");
		let currentIndex = items.indexOf(currentItem);
		let next = currentIndex + Math.sign(direction);

		next = (next < 0 ? items.length - 1 : next) % items.length;

		currentItem.classList.remove("current");
		items[next].classList.add("current");

		testimonials.classList.toggle("right", direction > 0);
		testimonials.classList.toggle("left", direction < 0);
	}

	let testimonials = document.getElementById("testimonials");

	testimonials.querySelector(".arrows .prev")?.addEventListener("click", () => scrollTestimonials(-1));

	testimonials.querySelector(".arrows .next")?.addEventListener("click", () => scrollTestimonials(1));
}