class ImageElement extends HTMLElement {
	constructor() {
		super();

		let img = document.createElement("img");

		img.src = this.getAttribute("src") ?? "";
		img.alt = this.getAttribute("alt") ?? img.src;

		if (this.hasAttribute("object-position")) img.style.objectPosition = this.getAttribute("object-position");

		let parallaxWrapper = document.createElement("span");
		parallaxWrapper.setAttribute("parallax-wrapper", "");

		let expandWrapper = document.createElement("span");
		expandWrapper.setAttribute("expand-wrapper", "");

		if (this.hasAttribute("expandable")) {
			expandWrapper.append(img);
			parallaxWrapper.append(expandWrapper);
			this.append(parallaxWrapper);

			this.addEventListener("click", () => {
				let detail = document.createElement("img");
				detail.src = this.getAttribute("full");
				detail.alt = img.alt;
	
				popup(img, detail);
			});
		}
		else if (this.hasAttribute("parallax-viewport")) {
			parallaxWrapper.append(img);
			this.append(parallaxWrapper);
		}
		else {
			this.append(img);
		}
	}
}

customElements.define("go-image", ImageElement);
// /** 
//  * Handler for full-sized image loading
//  * @param {HTMLImageElement} img
//  * */
// "img[full]".f.for = img => {
	
// }

// "[image-expand] > img".f.for = img => {
// 	img.addEventListener("click", () => {
		
// 	});
// }