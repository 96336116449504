if (document.body.classList.contains("AboutPage")) {
	const shiftTeam = direction => {
		/** @type {HTMLElement} */
		let carousel = document.querySelector("#team .carousel");
		let currentOffset = parseInt(carousel.style.getPropertyValue("--offset") || "0");
		let nextOffset = Math.min(Math.max(currentOffset + direction, 0), Math.max(carousel.children.length - Math.floor(carousel.clientWidth / 368), 0));

		carousel.style.setProperty("--offset", nextOffset);
	}

	const accordionSizing = () => {
		let max = 0;
		
		for (let content of document.querySelectorAll("#work .content")) {
			if (content instanceof HTMLElement) {
				let { height } = content.getBoundingClientRect();

				max = Math.max(max, height);

				content.parentElement.style.setProperty("--content-height", height + "px");
			}
		}

		document.getElementById("work").style.setProperty("--max-content", max + "px");
	}

	document.querySelector("#team .prev").addEventListener("click", () => shiftTeam(-1));
	document.querySelector("#team .next").addEventListener("click", () => shiftTeam(1));

	addEventListener("DOMContentLoaded", accordionSizing);
	addEventListener("resize", accordionSizing);

	/**
	 * Accordion controls
	 */
	"#work .heading".f.for = heading => {
		const activate = () => {
			let work = document.getElementById("work");

			work.querySelectorAll(".previous").forEach(el => el.classList.remove("previous"));
			work.querySelectorAll(".active").forEach(el => {
				el.classList.remove("active");
				el.classList.add("previous");
			});

			work.querySelector(`[data-id="${heading.dataset.id}"]`).classList.add("active");
			heading.classList.add("active");
		}

		heading.addEventListener("focus", activate);
		heading.nextElementSibling.addEventListener("focusin", activate);
	}
}