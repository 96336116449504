/**
 * Tracks a promise chain used for animation delays
 * @type {{ [sequence: string]: Promise<void> }}
 */
const sequenceTracking = {};

/**
 * Breaks apart HTML content into discrete `span` lines for animation
 */
"[animation-auto-appear]".f.for = text => {
	const breakLines = target => {
		let line = [];

		const pushWrapper = br => {
			let wrapper = document.createElement("span");
			wrapper.setAttribute("auto-line", "");
			wrapper.setAttribute("animation-appear", text.getAttribute("animation-auto-appear"));

			if (text.hasAttribute("animation-sequence")) wrapper.setAttribute("animation-sequence", text.getAttribute("animation-sequence"));

			for (let content of line) wrapper.append(content);
		
			if (br) {
				target.insertBefore(wrapper, br);
			}
			else {
				target.append(wrapper);
			}

			line = [];
		}
	
		for (let node of Array.from(target.childNodes)) {
			if (node.nodeType === document.ELEMENT_NODE) {
				if (node.tagName === "BR" && line.length > 0) {
					pushWrapper(node);
				}
				else if (["P", "H1", "H2", "H3", "H4", "H5", "H6"].includes(node.tagName)) {
					breakLines(node);
				}
				else {
					line.push(node);
				}
			}
			else {
				line.push(node);
			}
		}

		if (line.length > 0) pushWrapper();
	}

	breakLines(text);
}

/**
 * Handler for appear animations
 */
"[animation-appear]".f.for = element => {
	const readyCheck = () => {
		if (!element.hasAttribute("animation-ready")) {
			let { top, height } = element.getBoundingClientRect();
			let position = "appearBottom" in element.dataset ? top + height : top;

			if (getComputedStyle(element).display !== "none" && position < innerHeight * 0.8) {
				setTimeout(() => element.setAttribute("animation-ready", ""), 100);
			}
		}
	}

	addEventListener("scroll", readyCheck, { passive: true });

	readyCheck();
}

/**
 * Parallax handler
 */
"[parallax-viewport]".f.for = viewport => {
	let virtualParallax = NaN;

	const update = () => {
		let { height, top } = viewport.getBoundingClientRect();
		let nextParallax = Math.max(Math.min((top + height / 2) / innerHeight * 2 - 1, 1), -1);

		if (nextParallax !== virtualParallax) {
			virtualParallax = nextParallax;
			viewport.style.setProperty("--parallax", virtualParallax);
		}

		requestAnimationFrame(update);
	}

	update();

	// addEventListener("scroll", update, {
	// 	passive: true
	// });

	// addEventListener("DOMContentLoaded", () => requestAnimationFrame(update));
}