class GalleryCarouselElement extends HTMLElement {
	generate() {
		let cloneFrom = 0;

		for (let i = 0; i < 11; i++) {
			let node;

			if (i in this.children) {
				node = this.children[i];
			}
			else {
				node = this.children[cloneFrom].cloneNode(true);
				cloneFrom++;
				this.append(node);
			}

			node.addEventListener("click", () => {
				if (node.classList.contains("focus")) {
					let detail = document.createElement("img");
					
					detail.src = node.getAttribute("full");
					detail.alt = node.getAttribute("alt");

					popup(node, detail);
				}
				else {
					this.classList.add("active-animation");
					this.place(parseInt(node.dataset.placement));
				}
			});
		}
	}

	place(offset) {
		let images = Array.from(this.children)
			.filter(img => !img.hasAttribute("dead"))
			.sort((a, b) => parseInt(a.dataset.placement) - parseInt(b.dataset.placement));

		if (offset < 0) images = images.reverse();

		for (let i = 0; i < Math.abs(offset); i++) {
			let clone = images[i].cloneNode(false);

			let placement = (6 + i) * Math.sign(offset);

			clone.style.setProperty("--offset-x", placement);
			clone.style.setProperty("--sign", Math.sign(placement));
			clone.style.setProperty("--rotation", Math.sign(placement) * (placement !== 0 ? 1 : 0));

			clone.dataset.placement = placement;

			clone.addEventListener("click", () => {
				if (clone.classList.contains("focus")) {
					let detail = document.createElement("img");
					
					detail.src = clone.getAttribute("full");
					detail.alt = clone.getAttribute("alt");

					popup(clone, detail);
				}
				else {
					this.classList.add("active-animation");
					this.place(parseInt(clone.dataset.placement));
				}
			});

			this.append(clone);
		}

		requestAnimationFrame(() => {
			Array.from(this.children).forEach((img, index) => {
				if (!("placement" in img.dataset)) {
					if (index === 0) {
						img.dataset.placement = 0;
					}
					else if (index % 2 === 0) {
						img.dataset.placement = index / 2;
					}
					else {
						img.dataset.placement = (index + 1) / -2; 
					}
				}

				let placement = parseInt(img.dataset.placement) - offset;

				if (Math.abs(placement) >= 6) {
					img.setAttribute("dead", "");
					setTimeout(() => img.remove(), 500);
				}

				img.style.setProperty("--offset-x", placement);
				img.style.setProperty("--sign", Math.sign(placement));
				img.style.setProperty("--rotation", Math.sign(placement) * (placement !== 0 ? 1 : 0));

				img.dataset.placement = placement;

				img.classList.toggle("focus", placement === 0);
			});
		});
	}
	
	constructor() {
		super();

		this.generate();
		
		requestAnimationFrame(() => {
			this.place(0);
			this.classList.add("processed");
		});
	}
}

customElements.define("go-gallery-carousel", GalleryCarouselElement);

if (document.body.classList.contains("HomePage")) {
	/**
	 * Updates the hero carousel from a given "direction", which is either positive or negative
	 * @param {number} direction 
	 */
	const moveHeroCarousel = (direction) => {
		if (typeof direction === "number") {
			let items = Array.from(document.querySelectorAll("#hero .image-viewport > .clip"));
			let currentIndex = items.findIndex(item => item.classList.contains("current"));

			items[currentIndex].classList.remove("current");
			items[(items.length + (Math.sign(direction) + currentIndex)) % items.length].classList.add("current");

			let viewport = document.querySelector("#hero .image-viewport");
			viewport.classList.toggle("left", direction < 0);
			viewport.classList.toggle("right", direction > 0);
		}
		else throw new TypeError(`direction must be a number, got ${typeof direction}`);
	}

	document.querySelector("#hero .arrows .prev").addEventListener("click", () => moveHeroCarousel(-1));
	document.querySelector("#hero .arrows .next").addEventListener("click", () => moveHeroCarousel(1));
}