if (document.body.classList.contains("BuzzPage")) {
	"#featured".f.for = featured => {
		const update = index => {
			featured.querySelectorAll(".active").forEach(el => el.classList.remove("active"));
			featured.querySelectorAll(`[data-index="${index}"]`).forEach(el => el.classList.add("active"));

			featured.querySelector(".prev").classList.toggle("enabled", index > 0);
			featured.querySelector(".next").classList.toggle("enabled", index < (parseInt(featured.getAttribute("post-count")) - 1));
		}

		const currentIndex = () => {
			return parseInt(featured.querySelector(".active").dataset.index);
		}

		featured.querySelector(".prev").addEventListener("click", () => {
			featured.classList.add("left");
			featured.classList.remove("right");

			update(Math.max(currentIndex() - 1, 0));
		});

		featured.querySelector(".next").addEventListener("click", () => {
			featured.classList.add("right");
			featured.classList.remove("left");

			let count = parseInt(featured.getAttribute("post-count"));
			let nextIndex = currentIndex() + 1;

			update(Math.min(nextIndex, count - 1));

			if (nextIndex >= count - 2) {
				fetch(`/the-buzz/load_featured_posts?offset=${encodeURIComponent(count)}&limit=1`)
				.then(res => res.text())
				.then(html => {
					let span = document.createElement("span");
					span.innerHTML = html;

					if (html) {
						featured.setAttribute("post-count", count + 1);
						featured.append(...span.childNodes);
					}
				});
			}
		});

		update(0);
	}
}