"[video-section]".f.for = video => {
	video.querySelector(".play").addEventListener("click", () => {
		let player = document.createElement("video");

		player.id = "fullscreen-video";
		player.controls = true;
		player.src = video.getAttribute("video-section");

		popup(video, player);

		requestAnimationFrame(() => player.play());
	});
}

"go-episode.released.video".f.for = episode => {
	episode.querySelector(".thumbnail-wrapper").addEventListener("click", event => {
		let player = document.createElement("video");

		player.id = "fullscreen-video";
		player.controls = true;
		player.src = episode.dataset.preview;

		popup(event.currentTarget, player);

		requestAnimationFrame(() => player.play());
	});
}