/**
 * Create a popup and animates it from the `animationSource`
 * @param {Element} animationSource 
 * @param {Element} contentElement 
 */
const popup = (animationSource, contentElement) => {
	if (animationSource instanceof Element) {
		if (contentElement instanceof Element) {
			let { x, y, width, height } = animationSource.getBoundingClientRect();
			let element = "body #popup".f.first;

			element.classList.add("ready");
			element.append(contentElement);

			element.style.setProperty("--source-x", `${x}px`);
			element.style.setProperty("--source-y", `${y}px`);
			element.style.setProperty("--source-width", `${width}px`);
			element.style.setProperty("--source-height", `${height}px`);

			element.classList.add("open");
		}
		else throw new TypeError(`animationSource must be an Element`);
	}
	else throw new TypeError(`animationSource must be an Element`);
}

const closePopup = () => {
	const element = document.getElementById("popup");

	if (element.classList.contains("open")) {
		element.classList.remove("open");

		if (element.children.length > 1) {
			setTimeout(() => element.children[1]?.remove(), 500);
		}
	}
}

addEventListener("keydown", event => event.key === "Escape" ? closePopup() : null);

"body #popup".f.actual.f["a.close"].actual.addEventListener("click", closePopup);