if (document.body.classList.contains("ContactPage")) {
	const form = document.getElementById("form");

	form.addEventListener("submit", event => {
		event.preventDefault();
		form.classList.add("locked");

		form.querySelector(".message-popup").textContent = "One moment...";

		let payload = Array.from(form.querySelectorAll("[name]")).map(input => `${encodeURI(input.name)}=${encodeURIComponent(input.value)}`).join("&");

		fetch(`${form.dataset.endpoint}?${payload}&page=${form.dataset.page}`, {
			method: "POST"
		})
		.then(res => {
			if (res.ok) {
				form.querySelectorAll("[name]").forEach(input => input.value = "");
				form.querySelector(".message-popup").innerHTML = "Thank You!<br/>We'll be in touch.";
			}
		});
	});
}