/**
 * Newsletter form controls
 */
"#newsletter-form".f.for = form => {
	form.addEventListener("submit", event => {
		event.preventDefault();

		if (!form.hasAttribute("submitted")) {
			form.parentElement.classList.add("submitted");
			form.setAttribute("submitted", "");

			fetch("/home/newsletter", {
				method: "POST",
				body: JSON.stringify({
					email: form.querySelector("input").value,
					securityToken: form.dataset.securityToken
				})
			});
		}
	});
}